import React from "react"
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from "../components/layout"
import Warranty60d from "../components/sixty-day-warranty"
import Warranty3y from "../components/three-year-warranty"
import ContactForm from "../components/contact-form"
import rehypeReact from "rehype-react"
import logo from '../images/lasting-clean-logo-diamond.svg'


export function PostLayout()
{
    return (
        <>
          <Helmet
            title="Decking Treatment Nano Protection"
            meta={[
                { name: 'description', content: "Decking Treatment Nano Protection" },
                { name: 'keywords', content: "Decking Treatment"  },
            ]}
          >
            <link rel="stylesheet" type="text/css" href="https://unpkg.com/tachyons@4.10.0/css/tachyons.min.css"/>
          </Helmet>
          <div className="pv3 bg-regal-blue flex items-center justify-center flex-column white">
            <div className="measure-wide">
              <div className="flex w-100">
                <img alt="Lasting Clean LTD" className="db ma0 h3" src={logo}/>
              </div>
              <div className="measure-wide center ph2">
                <div id="post-content" className="">
                  <div className="pt1 white">
                    <h2 className="f5 mv3 mv4-ns"
                    ></h2>

                    <h1 className="f2-ns f3 mv3 mv4-ns white"
                    >
                      A Decking Treatment That Gives Your Deck <u>Self Cleaning Superpowers</u>
                    </h1>

                    <h2 className="f5 lh-title i fw1 mv3 mv4-ns white" >
                      <span className=""
                      >
                        We can treat your timber deck to give it a water repellent, easy-to-clean property, saving you time and money.
                      </span>
                    </h2>

                    <div className="pt2">
                      <ul>
                        <li><strong>Easy to keep clean.</strong> Our decking treatment forms an invisible barrier around timber molecules, which means your deck gets a self-cleaning effect because even rain can wash away dirt from your surface. </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div></div>


          <footer className="ph3 ph4-ns pv5 bt b--black-10 black-70">
            <a href="mailto:info@LastingClean.co.nz" className="link b f3 f2-ns dim black-70 lh-solid">info@LastingClean.co.nz</a>
            <p className="f6 db b ttu lh-solid mt2">© 2018 Lasting Clean Limited</p>
            <div className="mt5">
              <a href="/privacy/"  title="Privacy" className="f6 dib ml2 mid-gray dim">Privacy</a>
            </div>
          </footer>



        </>
    );

}

export default PostLayout
