import React from 'react'
import {Link} from 'gatsby'
import logo from '../images/lasting-clean-logo-diamond.svg'
import {FaPhone} from 'react-icons/fa'

const NavLink = ({href, title}) => (
    <Link className="w-50 w-auto-ns f6 f4-l link bg-animate white hover-bg-light-blue hover-black dib pa3 ph4-l"
          activeClassName="underline"
          to={href}
          exact={true}
          >{title}
    </Link>
)
const Header = ({ siteTitle }) => (
    <div>
      <header className="">
        <div className="tc b f3-ns f4 pv2">Phone us on <a href="tel:09-886-9733">09-886-9733</a> for a free assessment and quote</div>
        <div className="pv3 bg-light-blue flex items-center justify-center flex-column flex-row-ns">
          <Link to="/#post-content">
            <img alt="Lasting Clean LTD" className="db ma0 h4" src={logo}/>
          </Link>
          <div className="mt2 mb0 f5 f4-l fw4 tc tr-ns ml3 mr2 pr2">
            <div className="b tc">Auckland, New Zealand</div>
            <div>Effective, long lasting, low maintenance and environmentally safe</div>
            <div className="b">glass, timber, metal, concrete and fabric restoration & treatment</div>
            <div className="tc">
              <Link to="/contact-us#post-content" className="v-mid b ttu tracked f6 link dim br3 ph3 pv2 ma2 mb0 dib white bg-black">
                <FaPhone className="mr2 f4 v-mid"/>
                <span className="v-mid">Contact Us</span>
              </Link>
            </div>
          </div>
        </div>
          <div className="tc f4 bg-regal-blue white pt2">Choose the surface you want protected:</div>
        <nav className="tc center bg-regal-blue flex flex-wrap justify-center">
          <NavLink href="/glass-treatment#post-content" title="Glass"/>
          <NavLink href="/timber-treatment#post-content" title="Timber"/>
          <NavLink href="/metal-treatment#post-content" title="Metal"/>
          <NavLink href="/concrete-treatment#post-content" title="Concrete"/>
          <NavLink href="/fabric-treatment#post-content" title="Fabric"/>
          {/*<NavLink href="/other-glass" title="Other Glass"/>*/}
        </nav>

      </header>
    </div>
)

export default Header;
