import React from 'react'
import Badge from '../images/60-days-guarantee.svg'

export default ({children}) => (
  <div className="flex items-center">
    <div className="w-25">
      <img src={Badge} className="mb0" alt="" />
    </div>
    <div className="w-75 ml2">{children}</div>
  </div>
)
